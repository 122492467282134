/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { BasicContent, Card, CTAGroup, CTA, ColumnItem, Columns, CVEListing, Hero, Layout, Section } from "../components";

const SecurityPage = ({ pageContext }) => {
  const data = useStaticQuery(graphql`
    query {
        allContentfulSecurityAdvisories(
          sort: { order: DESC, fields: datePosted }
          filter: {
            childrenContentfulSecurityAdvisoriesShortDescriptionTextNode: {}
            node_locale: { eq: "en" }
          }
        ) {
          nodes {
            url
            title
          }
        }
    }
`
  )
  const advisories = [...data.allContentfulSecurityAdvisories.nodes]

  const resources = [
    {
      title: `Report a Vulnerability`,
      body: `<p>Report a security vulnerability in Okta products or services</p>`,
      ctaUrl: "https://www.okta.com/vulnerability-reporting-policy/",
      ctaText: "Learn more",
    },
    {
      title: `Set a Primary Security Contact`,
      body: `<p>The Primary Security Contact may be used to alert your security and privacy team if Okta identifies a specific threat to your organization requiring notification.</p>`,
      ctaUrl: "https://support.okta.com/help/s/article/super-admins-leverage-the-okta-help-center-to-review-and-update-your-companys-primary-security-contact-and-cio-ciso-contact?language=en_US",
      ctaText: "Learn more",
    },
    {
      title: `Okta Bug Bounty`,
      body: `
        <p>Learn more about scope and rewards at:</p>
        <p><a href="https://bugcrowd.com/okta">https://bugcrowd.com/okta</a></p>
        <p><a href="https://bugcrowd.com/auth0-okta">https://bugcrowd.com/auth0-okta</a></p>`,
    },
    {
      title: `Okta Security Blog`,
      tag: `h3`,
      body: `<p>Latest security articles and news</p>`,
      ctaUrl: "https://sec.okta.com/articles",
      ctaText: "Learn more",
    },
  ]

  return (
    <Layout pageContext={pageContext}>
      <Hero
        bgColor="primary"
        title={{
          display: "default",
          text: "Okta security advisories",
          tag: "h1"
        }}
      >
        <div sx={{ pt: "2rem" }} />
        <CTAGroup
          flexDirection={"row"}
          alignItems={"start"}
        >
          <CTA
            href={"https://trust.okta.com/security-advisories.xml"}
            text={"Subscribe to RSS Feed"}
            size={"regular"}
            style={"primary"}
          />
        </CTAGroup>
      </Hero>
      <Section paddingTop="small" paddingBottom="small" >
        <BasicContent
          title={`View latest advisories`}
          headerTag={`h2`}
        />
        <div sx={{ pt: "7px" }} />
        <BasicContent
          body={`<p><a href="https://trust.okta.com/security-advisory/legal-disclaimer">Legal Disclaimer</a></p>`}
        />
        <div sx={{ pt: "7px" }} />
        <CVEListing
          advisories={advisories}
        />
      </Section>
      <Section paddingTop="small" paddingBottom="small" >
        <BasicContent
          title={`Reporting and resources`}
          headerTag={`h2`}
        />
        <div sx={{ pt: "7px" }} />
        <Columns horizontalAlignment={"flex-start"} verticalAlignment={"stretch"}>
          {
            resources.map((card) => <ColumnItem width={"25"}>
              <Card
                title={card.title}
                tag={`h3`}
                body={card.body}
                ctaUrl={card.ctaUrl}
                ctaText={card.ctaText}
                overrideStyles={{ height: "100%" }}
              />
            </ColumnItem>)
          }

        </Columns>
      </Section>
    </Layout>
  )
}

export default SecurityPage